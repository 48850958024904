<style>
.input-readonly>div>.vs-inputx {
  background-color: #e6e6e6;
}
</style> 
<template>
  <div>

    <div class="vx-row ">
      <div class="vx-col w-full">
        <div class="vx-row mb-6" style="width: 50%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>Deposit Date</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full whitespace-no-wrap">
            <div class="mt-4 flex justify-between">
              <date-range-picker style="min-height: 40px" class="w-full" ref="picker" opens="center"
                :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }" :singleDatePicker="false" :timePicker="false"
                :timePicker24Hour="false" :showWeekNumbers="false" :showDropdowns="false" :autoApply="true"
                v-model="date" :linkedCalendars="true">
                <template v-slot:input="picker" style="min-width: 350px">
                  {{ globalDateFormat(picker.startDate) }} -
                  {{ globalDateFormat(picker.endDate) }}
                </template>
              </date-range-picker>
              <vs-button class="ml-4" color="danger" icon-pack="feather" icon="icon-x" @click="
                () => {
                  this.date = { startDate: null, endDate: null }
                }
              "></vs-button>
              <!-- <template>
              <vx-tooltip text="reset">
                <vs-button
                  type="line"
                  icon-pack="feather"
                  icon="icon-refresh-cw"
                  @click.stop="handleResetInvoiceDateFilter()"
                />
              </vx-tooltip>
            </template> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="vx-row mb-12">
      <div class="vx-col w-full">
        <div class="vx-row mb-6" style="width: 50%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>Operating Unit Bank </span>
            <div class="vx-col sm:w-1/3 w-full"></div>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect class="selectExample" v-model="operatingUnitBank" :options="optionOperatingUnitBank"
              :multiple="false" :allow-empty="false" :group-select="false" :max-height="160" :limit="4"
              placeholder=" Type to search">
              <template slot="singleLabel" slot-scope="dt">
                <span class="option__desc">
                  <span class="option__title">
                    {{ dt.option.BankName }}
                    <br />
                    {{ dt.option.AccountName }}
                    <br />
                    {{ dt.option.AccountNumber }}
                  </span>
                </span>
              </template>

              <template slot="option" slot-scope="dt">
                <div class="option__desc">
                  <span class="option__title">
                    {{ dt.option.BankName }}
                    <br />
                    {{ dt.option.AccountName }}
                    <br />
                    {{ dt.option.AccountNumber }}
                  </span>
                </div>
              </template>
            </multiselect>
          </div>
        </div>
      </div>
      <div class="vx-col w-full">
        <div class="vx-row mb-6" style="width: 50%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>Operating Unit / Territory</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect class="selectExample" v-model="operatingUnit" :options="optionOU" :multiple="true"
              :allow-empty="true" :group-select="false" :max-height="120" :limit="3" placeholder="Type to search"
              track-by="ID" label="Name">
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title">
                    <template v-if="props.option.ID != 0">
                      ({{ props.option.Code }}), ({{
                          props.option.OperatingUnitTerritory.Territory.code
                      }})
                      {{ props.option.OperatingUnitTerritory.Territory.name }}
                    </template>
                    <template v-else>
                      ({{ props.option.Code }})
                    </template>
                  </span>
                </span>
              </template>

              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title">

                    <template v-if="props.option.ID != 0">
                      ({{ props.option.Code }}), ({{
                          props.option.OperatingUnitTerritory.Territory.code
                      }})
                      {{ props.option.OperatingUnitTerritory.Territory.name }}
                    </template>
                    <template v-else>
                      ({{ props.option.Code }})
                    </template>
                  </span>
                </div>
              </template>
            </multiselect>
          </div>
        </div>
      </div>
      <div class="vx-col w-full">
        <div class="vx-row mb-6" style="width: 50%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">

          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-button class="ml-4 mt-2" color="primary" icon-pack="feather" icon="icon-printer"
              v-on:click="downloadExcel">Download Excel</vs-button>
          </div>

        </div>
      </div>

      <div v-bind:class="[
        detail ? detailShow + ' vertical-divider' : '',
        detailHide,
      ]">
        <div class="tab-text">
          <data-table :responseData="responseData" :propsParams="params" :header="header"
            @reloadDataFromChild="reloadData">
            <template slot="tbody">
              <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.bankStatementLines" :class="tr.class">
                
                <vs-td>
                  {{ tr.TypeTransaction }}
                </vs-td>
                <vs-td>
                  {{ tr.BranchCode }}
                </vs-td>
                <vs-td>
                  {{ tr.NameVa }}
                </vs-td>
                <vs-td>
                  <div v-if="tr.TransactionValue >= 0">
                    Value : {{ formatPrice(tr.TransactionValue) }}<br />
                  </div>
                  <div v-else>
                    <vs-alert color="warning" class="mt-3" icon-pack="feather" close-icon="icon-x">
                      Value : {{ formatPrice(tr.TransactionValue) }}
                    </vs-alert>
                  </div>
                  Statement Code :
                  {{ tr.BankStatement.Code }}<br />
                  Reference Code :
                  {{ tr.ReferenceCode ? tr.ReferenceCode : "-" }}<br />
                  No Giro : {{ tr.NoGiro ? tr.NoGiro : "-" }}<br />
                  Remark : {{ tr.Note }}<br />
                  Txn Date : {{ dateFormat(tr.TxnDate) }}<br />
                  <template v-if="tr.CashBankID != 0">
                    Cash Bank Code : {{ tr.CashBankCode }}
                  </template>
                  <template v-if="tr.PaymentID != 0">
                    Payment Code : {{ tr.PaymentCode }}
                  </template>

                  <!-- Reference Code : {{tr.ReferenceCode}}<br>
            Cash Bank Code : {{tr.CashBankCode}} -->
                </vs-td>
              </vs-tr>
            </template>
          </data-table>
          <!-- Approve Prompt -->
        </div>
      </div>
      <!-- form -->
      <transition name="detail-fade">
        <div v-if="detail" v-bind:class="[
          detail ? detailShow + 'vx-col md:w-1/2 w-full  mb-base' : '',
          detailHide,
        ]">
          <vs-row>
            <vs-col style="padding-bottom: 8px" vs-offset="8" vs-type="flex" vs-justify="rigth" vs-align="rigth"
              vs-w="4">
              <vs-button class="ml-auto" size="small" v-on:click="handleClose" color="grey" icon-pack="feather"
                icon="icon-x-square">Close</vs-button>
            </vs-col>
            <vs-col vs-offset="0" vs-type="flex" vs-justify="center" vs-align="center" vs-w="1">
              <vs-button size="small" v-on:click="handleClose" color="grey" icon-pack="feather"
                icon="icon-x-square"></vs-button>
            </vs-col>
            <template v-if="!formGroup">
              <detail-div @close="handleClose" :selected="selectedData" :operatingUnitText="operatingUnitText" />
            </template>
            <template v-else>
              <group-div @close="handleClose" :selected="selectedData" :operatingUnitText="operatingUnitText" />
            </template>
          </vs-row>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import detail from "./detail.vue";
import group from "./group.vue";
import DateRangePicker from "vue2-daterange-picker";
//you need to import the CSS manually
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

// import { dataPaymentCollection } from "../../../../../services/api/invoice";
export default {
  components: {
    "detail-div": detail,
    "group-div": group,
    DateRangePicker,
  },
  props: {
    selected: Object,
    option: Object,
  },
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "id",
        status: 0,
      },
      date: {
        startDate: null,
        endDate: null,
      },
      header: [
        {
          text: "Type Transaction",
          value: "type_transaction",
          // width: '5%'
        },
        {
          text: "Branch Code",
          value: "branch_code",
        },
        {
          text: "Name VA",
          value: "name_va",
        },
        {
          text: "Informasi",
          sortable: false,
        },
      ],
      optionOU: [],
      formGroup: false,
      operatingUnit: {},
      operatingUnitText: '',
      methods: ["", "Cash", "Giro", "Cheque", "Transfer"],
      responseData: {},
      responseDataReconciled: {},
      detailShow: "vx-col md:w-1/2 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      notes: "",
      bankStatement: {},
      operatingUnitBank: {},

      optionOperatingUnitBank: [],
      bank: {},
      optionBank: [],
      approveOrReject: false,
      approvalPrompt: false,
      rejectPrompt: false,
      selectedData: {},
      bankStatementID: null,
    };
  },
  computed: {
    // operatingUnit(){
    //   console.log(this.operatingUnit,"ou")
    // }
  },
  watch: {
    operatingUnit() {
      console.log(this.operatingUnit)
      // const checkAll = this.operatingUnit.filter(v => {
      //   return v.ID == 0
      // })

      if (this.operatingUnit.length == 0) {
        this.operatingUnit = { ID: 0, Code: 'All', Name: 'All' }
        this.operatingUnitText = ''
      } else {
        if (this.operatingUnit[this.operatingUnit.length - 1].ID == 0) {
          this.operatingUnit = { ID: 0, Code: 'All', Name: 'All' }
          this.operatingUnitText = ''
        } else {
          const operatingUnit = this.operatingUnit.filter(v => {
            return v.ID != 0
          })
          if (this.operatingUnit.length > operatingUnit.length) {
            this.operatingUnit = operatingUnit
          }
          console.log(operatingUnit)
          const territory = []
          this.operatingUnit.map(v => {
            territory.push(v.OperatingUnitTerritory.TerritoryID)
          })
          this.operatingUnitText = territory.join(",")
        }
      }
      // this.getBankForm(this.operatingUnit)
      this.reloadData(this.params)
      this.reloadDataReconciled(this.params);
      // this.handleClose()
    },
    "date.startDate"() {
      this.reloadData(this.params)
      this.reloadDataReconciled(this.params);
    },
    "date.endDate"() {
      this.reloadData(this.params)
      this.reloadDataReconciled(this.params);
    },
    operatingUnitBank() {
      console.log(this.operatingUnit, "ou");
      // this.getBankForm(this.operatingUnit)
      this.reloadData(this.params);
      this.reloadDataReconciled(this.params);
      // this.handleClose()
    },
  },
  mounted() {
    this.getBankForm();
  },
  methods: {
    handleUnGroup(ID) {
      this.$vs.loading();
      this.$http.delete('/api/v1/bank-statement/group-manual/' + ID)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose(true);

            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });

          }
        }).catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "error catch",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          console.log(e)
        })
    },
    getBankForm() {
      // this.$http.get('/api/v1/cash-bank/form-bank/' + this.operatingUnit.OperatingUnitTerritory.TerritoryID).then((r) => {
      this.$http
        .get("/api/v1/cash-bank/form-bank/0")
        .then((r) => {
          // this.optionTerritory = r.data.territory
          // this.optionTerritory = r.data.territories
          // this.optionBank = r.data.bank
          // console.log(r)
          this.getOptionOU()
          this.optionOperatingUnitBank = r.data.operatingUnitBank;
          this.operatingUnitBank = r.data.operatingUnitBank[0];
        })
        .catch((e) => {
          console.log(e);
        });
    },
    findBankStatement(id) {
      this.$http
        .get("/api/v1/bank-statement/data-table/" + id)
        .then((r) => {
          if (r.code == 200) {
            this.bankStatement = r.data.bankStatement;
          } else {
            this.$vs.notify({
              color: "danger",
              title: r.message,
              text: "error catch",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.handleBack();
          }
        })
        .catch((e) => {
          this.$vs.notify({
            color: "danger",
            title: "error catch",
            text: "error catch",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          this.handleBack();
        });
    },
    handleBack() {
      this.$router.push("/finance/bank-statement");
    },
    getOptionOU() {
      this.optionOU = []
      this.$http.get("/api/v1/master/operating-unit").then((resp) => {
        // this.optionOU = [...{ID:0,Code:'All',Name:'All'},resp.data.records];
        this.optionOU.push({ ID: 0, Code: 'All', Name: 'All' })
        resp.data.records.map(v => {
          this.optionOU.push(v)
        })
        this.operatingUnit = { ID: 0, Code: 'All', Name: 'All' };
      });
    },
    dateFormat(value) {
      return moment(String(value)).format("DD/MM/YYYY");
    },
    handleApprove(selected) {
      this.selectedData.class = "";
      this.approveOrReject = 1;
      selected.class = "highlight";
      this.selectedData = selected;
      this.rejectPrompt = false;
      this.approvalPrompt = true;
      console.log(this.selectedData);
    },
    handleReject(selected) {
      this.selectedData.class = "";
      this.approveOrReject = 0;
      selected.class = "highlight";
      this.selectedData = selected;
      this.approvalPrompt = false;
      this.rejectPrompt = true;
    },

    closeDetail() {
      this.activePrompt2 = false;
      this.detail = false;
      this.selectedData.class = "";
      this.selectedData = {
        ID: 0,
      };
      this.reloadData(this.params);
    },
    downloadExcel() {
      const params = this.params
      const fileTitle =
        "EXPORT_UNRECONCILE_BANK_STATEMENT" +
        "_" +
        this.operatingUnitBank.BankName +
        "_" +
        this.operatingUnitBank.AccountNumber +
        "_" +
        moment(this.startDate).format("YYYY-MM-DD") +
        "_-_" +
        moment(this.endDate).format("YYYY-MM-DD");

      this.$http
        .get("/api/v1/bank-statement/by-bank/export/data-table", {
          params: {
            status: 0,
            search: params.search,
            length: params.length,
            page: params.page,
            order: params.sort,
            sort: params.order,
            bank_id: this.operatingUnitBank.BankID,
            account_number: this.operatingUnitBank.AccountNumber,
            territory_text: this.operatingUnitText,
            start_collect_date: this.date.startDate ? moment(this.date.startDate).format("YYYY-MM-DD") : null,
            end_collect_date: this.date.endDate ? moment(this.date.endDate).format("YYYY-MM-DD") : null,
            // operating_unit_id: this.operatingUnit ? this.operatingUnit.ID : 0,
          },
          responseType: "arraybuffer",
          headers: {
            Accept: "application/octet-stream",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.status == "error") {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            var fileURL = window.URL.createObjectURL(new Blob([resp]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;

            fileLink.setAttribute("download", fileTitle + ".xlsx");

            document.body.appendChild(fileLink);
            fileLink.click();
          }
        });
    },
    dataTable(params) {
      console.log(this.operatingUnitBank.AccountNumber, 'this.operatingUnitBank.AccountNumber')
      console.log(this.operatingUnitBank, "dttb");
      return new Promise((resolve, reject) => {
        this.$http
          .get("/api/v1/report/bank-statement/by-bank/data-table", {
            params: {
              status: params.status,
              search: params.search,
              length: params.length,
              page: params.page,
              order: params.sort,
              sort: params.order,
              bank_id: this.operatingUnitBank.BankID,
              account_number: this.operatingUnitBank.AccountNumber,
              territory_text: this.operatingUnitText,
              start_collect_date: this.date.startDate ? moment(this.date.startDate).format("YYYY-MM-DD") : null,
              end_collect_date: this.date.endDate ? moment(this.date.endDate).format("YYYY-MM-DD") : null,
              // operating_unit_id: this.operatingUnit ? this.operatingUnit.ID : 0,
            },
          })
          .then((r) => {
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    handleShow(selected) {
      this.selectedData.class = "";
      selected.class = "highlight";
      this.selectedData = selected;
      this.formGroup = false
      this.detail = true;
    },
    handleGroup(selected) {
      this.selectedData.class = "";
      selected.class = "highlight";
      this.selectedData = selected;
      this.formGroup = true
      this.detail = true;
    },
    handleClose(reload = true) {
      if (reload) {
        this.reloadData(this.params);
        this.reloadDataReconciled(this.params);
      }
      this.selectedData.class = "";
      this.detail = false;
      this.selectedData = {
        ID: 0,
      };
    },
    formatPrice(angka, prefix = "") {
      return angka
        .toString()
        .replace(/,/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    reloadData(params) {
      this.handleClose(false)
      this.params = params;
      this.params.status = 0;
      console.log(params);
      this.$vs.loading();

      const dataTable = this.dataTable(params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.data = r.data.bankStatementLines;
          this.responseData = r.data;
          this.responseData.length = r.data.bankStatementLines.length;
          // this.checkedAll = false;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    reloadDataReconciled(params) {
      this.handleClose(false)
      this.params = params;
      this.params.status = 1;
      console.log(params);
      this.$vs.loading();

      const dataTable = this.dataTable(params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.data = r.data.bankStatementLines;
          this.responseDataReconciled = r.data;
          this.responseDataReconciled.length = r.data.bankStatementLines.length;
          // this.checkedAll = false;
          this.$vs.loading.close();

        } else {
          this.$vs.loading.close();
        }
      });
    },
  },
};
</script>
<style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}

.core-enter-active {
  transition: all 0.3s ease;
}

.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
.core-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
  {
  transform: translateX(10px);
  opacity: 0;
}

.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}

.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}

.highlight>td {
  background-color: #d0cdf0;
}

.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}

.vs-dialog {
  max-width: 1000px;
}
</style>
