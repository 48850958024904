<template>
  <vx-card title="Report Bank Statement">
    <process></process>
    <!-- <vs-tabs>
      <vs-tab label="Process">
        <div class="tab-text">
        </div>
      </vs-tab>
      <vs-tab label="Payment Waiting Reconciled">
        <div class="tab-text">
          <waiting-reconcile></waiting-reconcile>
        </div>
      </vs-tab>
    </vs-tabs> -->
  </vx-card>
</template>
<script>
// import Upload from "./upload/upload.vue";
import Process from './process/index.vue';
// import Open from './open/open.vue';
// import WaitingReconcile from "./waiting-reconcile/waiting-reconcile.vue"
export default {
  components: {
    // "upload": Upload,
    "process": Process,
    // "open" : Open,
    // "waiting-reconcile" : WaitingReconcile
  }
};
</script>